import { faSquare, faSquareCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNil, merge } from 'lodash';
import { useEffect } from 'react';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { ISelectOption } from '../../types';
import { cn } from '../../utils/classUtils';
import ControlledInputLabelComponent from './ControlledInputLabelComponent';
import FieldErrorMessage from './FieldErrorMessage';

export interface ControlledConsentCheckboxInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  trueOption: ISelectOption;
  falseOption: ISelectOption;
}

const ControlledConsentCheckboxInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  defaultValue,
  trueOption,
  falseOption,
  shouldUnregister = true,
  ...controllerProps
}: ControlledConsentCheckboxInputProps<TFieldValues, TName>) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    shouldUnregister,
    rules: merge(controllerProps.rules || {}, {
      validate: async (value: string) => {
        if (value?.toString() === falseOption.value?.toString()) {
          return controllerProps.rules?.required;
        }

        return undefined;
      },
    }),
  });

  useEffect(() => {
    if (isNil(value)) {
      onChange('false');
    }
  }, [onChange, value]);

  const isTrueOptionSelected =
    value?.toString() === trueOption.value?.toString();

  return (
    <div>
      <div className='flex flex-row items-center gap-2'>
        <button
          type='button'
          className={cn('flex flex-row items-center')}
          onClick={() => {
            if (isTrueOptionSelected) {
              onChange(falseOption.value.toString());
            } else {
              onChange(trueOption.value.toString());
            }
          }}
        >
          {isTrueOptionSelected ? (
            <FontAwesomeIcon
              icon={faSquareCheck}
              fontSize={20}
              className='text-reskin-primary-dark'
            />
          ) : (
            <FontAwesomeIcon
              icon={faSquare}
              fontSize={20}
              className='text-reskin-primary-dark'
            />
          )}
        </button>
        {!!label && (
          <ControlledInputLabelComponent
            label={label}
            containerClassName='mb-0 '
          />
        )}
      </div>
      <FieldErrorMessage message={error?.message} />
    </div>
  );
};

export default ControlledConsentCheckboxInput;
