import { faArrowLeftLong } from '@fortawesome/pro-light-svg-icons';
import { faArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { signOutUser } from '../../slices/AuthSlice';
import { useAppDispatch } from '../../slices/store';
import { cn } from '../../utils/classUtils';
import ConfigDrivenContainerLayoutHeaderNavLink from './ConfigDrivenContainerLayoutHeaderNavLink';

interface ConfigDrivenContainerLayoutHeaderProps {
  hideMyLoans?: boolean;
  hideMyTasks?: boolean;
}

const ConfigDrivenContainerLayoutHeader: FC<
  ConfigDrivenContainerLayoutHeaderProps
> = ({ hideMyLoans, hideMyTasks }) => {
  const { borrowerId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <div className='lg:flex hidden flex-row p-4 bg-white border-b border-silver sticky top-0 right-0 z-50'>
      <div
        className={cn('flex flex-row flex-1 justify-between gap-x-5', {
          'flex-row-reverse': hideMyLoans,
        })}
      >
        {!hideMyLoans && (
          <ConfigDrivenContainerLayoutHeaderNavLink
            label='My Loans'
            path='/loans'
            leftIcon={faArrowLeftLong}
          />
        )}
        <div className='flex flex-row items-center'>
          {!hideMyTasks && borrowerId && (
            <ConfigDrivenContainerLayoutHeaderNavLink
              label='My Tasks'
              path={`/pre-approval-form/${borrowerId}/tasks`}
            />
          )}
          {borrowerId && (
            <ConfigDrivenContainerLayoutHeaderNavLink
              label='File Cabinet'
              path={`/pre-approval-form/${borrowerId}/file-cabinet`}
            />
          )}
          <ConfigDrivenContainerLayoutHeaderNavLink
            path='#'
            label='Logout'
            leftIcon={faArrowRightFromBracket}
            onClick={async () => {
              await dispatch(signOutUser());
              navigate('/');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfigDrivenContainerLayoutHeader;
