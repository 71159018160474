import { faArrowUpRight } from '@fortawesome/pro-light-svg-icons';
import {
  faEnvelope,
  faEye,
  faEyeSlash,
  faLock,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';
import React, { useState } from 'react';
import { get, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import AuthPageLayout from '../components/Layout/AuthPageLayout.tsx';
import ControlledConsentInput from '../components/inputs/ControlledConsentInput';
import ControlledPhoneNumberInput from '../components/inputs/ControlledPhoneNumberInput';
import ControlledTextInput from '../components/inputs/ControlledTextInput';
import {
  ELECTRONIC_DISCLOSURE_CONSENT_LINK,
  PRIVACY_POLICY_LINK,
  TERMS_OF_USE_LINK,
} from '../constants/OneRealMortgageConstants';
import { BorrowersApi, RegisterBorrower } from '../openapi/atlantis';
import { AuthControllerApi, LoginRequest } from '../openapi/keymaker';
import ErrorService from '../services/ErrorService.ts';
import { fetchAuthUserDetail } from '../slices/AuthSlice';
import { useAppDispatch } from '../slices/store';
import { AnalyticsEventEnum } from '../types.ts';
import { setAuthCookie } from '../utils/AuthUtils';
import { getCommonsApiErrorMessage } from '../utils/ErrorUtils.ts';
import Logger from '../utils/Logger';
import {
  getAtlantisConfiguration,
  getKeymakerConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { EMAIL_VALIDATIONS, PASSWORD_VALIDATIONS } from '../utils/Validations';

interface SignUpRouteProps {}

interface FormData {
  firstName: string;
  lastName: string;
  emailAddress: string;
  password: string;
  confirmPassword: string;
  submit: string;
  phoneNumber: string;
  consentedToTerms: boolean;
  consentByElectronicSignature: boolean;
}

const SignUpRoute: React.FC<SignUpRouteProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    leadId,
    loanId,
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    inviterAuthorizedPersonId,
  } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const [hidePassword, setHidePassword] = useState(true);
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      firstName: firstName as string,
      lastName: lastName as string,
      emailAddress: emailAddress as string,
      phoneNumber: phoneNumber as string,
    },
  });

  const onSubmit = async (values: FormData) => {
    const registerBorrowerRequest: RegisterBorrower = {
      firstName: values.firstName,
      lastName: values.lastName,
      emailAddress: values.emailAddress,
      phoneNumber: values.phoneNumber,
      password: values.password,
      consentedToTerms: values.consentedToTerms,
      leadId: leadId as string,
      loanId: loanId as string,
      inviterAuthorizedPersonId: inviterAuthorizedPersonId as string,
    };

    const loginRequest: LoginRequest = {
      usernameOrEmail: values.emailAddress,
      password: values.password,
    };

    try {
      await new BorrowersApi(await getAtlantisConfiguration()).registerBorrower(
        registerBorrowerRequest,
      );

      const {
        data: { accessToken },
      } = await new AuthControllerApi(
        await getKeymakerConfiguration(),
      ).authenticateUser(loginRequest);
      setAuthCookie(accessToken!);

      await dispatch(fetchAuthUserDetail(leadId as string));
      navigate('/');
    } catch (e: any) {
      Logger.error('Unable to sign up', e);
      ErrorService.notifyIgnoreHandled('Unable to sign up', e);
      setError('submit', {
        message: getCommonsApiErrorMessage(e),
      });
    }
  };

  return (
    <AuthPageLayout
      eventName={AnalyticsEventEnum.SIGNUP_SCREEN_VIEWED}
      containerClassName='lg:mt-10 mt-40'
    >
      <form onSubmit={handleSubmit(onSubmit)} className='w-full lg:w-4/5'>
        <div className='pb-5 lg:pb-9 lg:mt-10'>
          <p className='font-poppins-medium lg:text-5xl text-2xl !lg:leading-[62px] text-reskin-primary-dark text-left font-medium'>
            Create your account
          </p>
          <div className='pt-1 lg:pt-3 font-inter-regular text-sm lg:font-inter-light lg:text-lg text-reskin-primary-dark'>
            <p>
              Already have an account with us?
              <Link to={leadId ? `/login?leadId=${leadId}` : '/login'}>
                <span className='text-reskin-primary-blue underline pl-1'>
                  Login
                </span>
              </Link>
            </p>
          </div>
        </div>
        {get(errors, 'submit') && (
          <div className='mb-5 flex bg-primary-coral rounded-lg p-2'>
            <p className='font-inter-medium text-white text-base'>
              {get(errors, 'submit').message}
            </p>
          </div>
        )}
        <div className='grid grid-cols-1 gap-4 lg:gap-6 pb-4 lg:pb-6'>
          <ControlledTextInput<FormData, 'firstName'>
            name='firstName'
            control={control}
            label='First Name'
            placeholder='First Name'
            startAdornment={
              <FontAwesomeIcon
                icon={faUser}
                fontSize={16}
                className='text-primary-skyblue'
              />
            }
            shouldUnregister={false}
            rules={{
              required: 'Please enter first name',
            }}
          />
          <ControlledTextInput<FormData, 'lastName'>
            name='lastName'
            control={control}
            label='Last Name'
            startAdornment={
              <FontAwesomeIcon
                icon={faUser}
                fontSize={16}
                className='text-primary-skyblue'
              />
            }
            placeholder='Last Name'
            shouldUnregister={false}
            rules={{
              required: 'Please enter last name',
            }}
          />
          <ControlledTextInput<FormData, 'emailAddress'>
            name='emailAddress'
            control={control}
            placeholder='Email address'
            label='Email address'
            startAdornment={
              <FontAwesomeIcon
                icon={faEnvelope}
                fontSize={16}
                className='text-primary-skyblue'
              />
            }
            shouldUnregister={false}
            rules={{
              required: 'Please enter email address',
              ...EMAIL_VALIDATIONS,
              validate: async (value) => {
                const { data: email } = await new AuthControllerApi(
                  await getKeymakerConfiguration(),
                ).checkEmailAvailability(value);

                return !email.available ? 'Email is already taken' : undefined;
              },
            }}
          />
          <ControlledPhoneNumberInput<FormData, 'phoneNumber'>
            name='phoneNumber'
            control={control}
            placeholder='Phone Number'
            label='Phone Number'
            rules={{
              required: 'Please enter phone number',
            }}
          />
          <ControlledTextInput<FormData, 'password'>
            placeholder='Password'
            name='password'
            label='Password'
            control={control}
            shouldUnregister={false}
            rules={PASSWORD_VALIDATIONS}
            type={hidePassword ? 'password' : 'text'}
            defaultValue=''
            startAdornment={
              <FontAwesomeIcon
                icon={faLock}
                fontSize={16}
                className='text-primary-skyblue'
              />
            }
            endAdornment={
              <FontAwesomeIcon
                onClick={() => setHidePassword(!hidePassword)}
                icon={hidePassword ? faEyeSlash : faEye}
                fontSize={20}
                className='text-primary-gray'
                data-testid='password'
              />
            }
            isPassword
          />
          <ControlledTextInput<FormData, 'confirmPassword'>
            placeholder='Confirm Password'
            name='confirmPassword'
            label='Confirm Password'
            control={control}
            shouldUnregister={false}
            startAdornment={
              <FontAwesomeIcon
                icon={faLock}
                fontSize={16}
                className='text-primary-skyblue'
              />
            }
            rules={{
              required: 'Please re-enter your password',
              validate: (value) =>
                getValues().password !== value
                  ? 'Passwords do not match'
                  : undefined,
            }}
            type={hideConfirmPassword ? 'password' : 'text'}
            defaultValue=''
            endAdornment={
              <FontAwesomeIcon
                onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
                icon={hideConfirmPassword ? faEyeSlash : faEye}
                fontSize={20}
                className='text-primary-gray'
                data-testid='confirmPassword'
              />
            }
            isPassword
          />
        </div>
        <div className='pb-3'>
          <ControlledConsentInput<FormData, 'consentedToTerms'>
            name='consentedToTerms'
            control={control}
            data-testid='consentedToTerms'
            labelComponent={
              <div data-testid='consentedToTerms'>
                <p className='font-inter-light text-xs lg:text-sm text-reskin-primary-dark lg:-mt-0.5'>
                  I have reviewed and agree to the{' '}
                  <span onClick={(e) => e.stopPropagation()}>
                    <a
                      className='text-reskin-primary-blue'
                      href={ELECTRONIC_DISCLOSURE_CONSENT_LINK}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Electronic Disclosure and Consent.
                    </a>
                  </span>
                </p>
              </div>
            }
            rules={{
              required: 'Please provide your consent to continue',
            }}
          />
        </div>

        <div className='pb-5'>
          <ControlledConsentInput<FormData, 'consentByElectronicSignature'>
            name='consentByElectronicSignature'
            control={control}
            data-testid='consentByElectronicSignature'
            labelComponent={
              <div data-testid='consentByElectronicSignature'>
                <p className='font-inter-light text-xs lg:text-sm text-reskin-primary-dark lg:-mt-0.5'>
                  I agree to the{' '}
                  <span onClick={(e) => e.stopPropagation()}>
                    <a
                      className='text-reskin-primary-blue'
                      href={PRIVACY_POLICY_LINK}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Privacy Policy
                    </a>{' '}
                    &{' '}
                    <a
                      className='text-reskin-primary-blue'
                      href={TERMS_OF_USE_LINK}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Terms of Use
                    </a>
                  </span>
                </p>
              </div>
            }
            rules={{
              required: 'Please provide your consent to continue',
            }}
          />
        </div>

        <div className='pb-5'>
          <p className='font-inter-light text-xs lg:text-sm text-reskin-grey-500 text-left'>
            By providing my phone number above, I consent by electronic
            signature to receive calls and text messages from One Real Mortgage
            made by any means or technology, including a prerecorded message,
            artificial voice, automatic telephone dialing system or other
            automated system for the selection and dialing of telephone numbers,
            for informational, transactional, marketing or any other purposes at
            my phone number. I understand that my consent to such calls and text
            messages is not required to purchase products from or use the
            services of One Real Mortgage.
          </p>
        </div>

        <div className='flex w-full'>
          <Button
            label='Sign Me Up!'
            type='submit'
            fullWidth
            rightIcon={
              <FontAwesomeIcon
                icon={faArrowUpRight}
                fontSize={16}
                className='text-white'
                data-test='loading-icon'
              />
            }
            gradientVariant='mintyfresh'
            isSubmitting={isSubmitting}
            onPress={() => {
              if (get(errors, 'submit')) {
                clearErrors('submit');
              }
            }}
          />
        </div>
      </form>
    </AuthPageLayout>
  );
};

export default SignUpRoute;
