import { FC } from 'react';
import { cn } from '../../utils/classUtils';

interface ControlledInputLabelComponentProps {
  label: string;
  containerClassName?: string;
}

const ControlledInputLabelComponent: FC<ControlledInputLabelComponentProps> = ({
  label,
  containerClassName,
}) => {
  return (
    <div className={cn('flex flex-row mb-1', containerClassName)}>
      <p className='font-inter-regular text-base text-black'>{label}</p>
    </div>
  );
};

export default ControlledInputLabelComponent;
