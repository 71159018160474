import { useLoadScript } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/src/utils/make-load-script-url';
import config from '../config';

const GOOGLE_MAPS_LIBRARIES: Libraries = ['places'];

export const useGoogleMapsAPILoader = (): boolean => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.gMapsAPIKey,
    libraries: GOOGLE_MAPS_LIBRARIES,
    language: 'en',
  });

  return isLoaded;
};
