import { faEye, faEyeSlash, faLock } from '@fortawesome/pro-regular-svg-icons';
import { faChevronsRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';
import React, { useState } from 'react';
import { get, useForm } from 'react-hook-form';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import AuthPageLayout from '../components/Layout/AuthPageLayout';
import ControlledTextInput from '../components/inputs/ControlledTextInput';
import { AuthControllerApi, LoginRequest } from '../openapi/keymaker';
import ErrorService from '../services/ErrorService';
import { AnalyticsEventEnum, ErrorCode } from '../types';
import { getKeymakerConfiguration } from '../utils/OpenapiConfigurationUtils';
import { PASSWORD_VALIDATIONS } from '../utils/Validations';

interface ChangePasswordRouteProps {}

interface FormData {
  usernameOrEmail: string;
  password: string;
  confirmPassword: string;
  submit: string;
}

const ChangePasswordRoute: React.FC<ChangePasswordRouteProps> = () => {
  const navigate = useNavigate();
  const [hidePassword, setHidePassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const {
    control,
    handleSubmit,
    clearErrors,
    getValues,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({ mode: 'onChange' });

  const location = useLocation();
  const { passwordToken, emailAddress } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const onSubmit = async (values: LoginRequest) => {
    try {
      const api = await new AuthControllerApi(await getKeymakerConfiguration());
      await api.changePassword({
        password: values.password,
        token: passwordToken as string,
      });

      navigate('/');
    } catch (e: any) {
      setError('submit', {
        type: 'server',
        message: e.response.data?.message || ErrorService.getErrorCode(e),
      });

      const errorCode = ErrorService.getErrorCode(e);
      if (errorCode === ErrorCode.SERVER_ERROR) {
        ErrorService.notify('Unable to reset password.', e);
      }
    }
  };

  if (!passwordToken || !emailAddress) {
    return <Navigate to='/login' replace />;
  }

  return (
    <AuthPageLayout
      eventName={AnalyticsEventEnum.CHANGE_PASSWORD_SCREEN_VIEWED}
      containerClassName='lg:mt-10'
    >
      <div className='w-full lg:w-4/5'>
        <div className='pb-4 lg:pt-6 lg:pb-14'>
          <p className='font-poppins-medium text-center lg:text-left lg:text-5xl text-2xl text-black !lg:leading-[62px]'>
            Change Password
          </p>
          <p className='font-inter-light text-center lg:text-left text-xs px-3.5 lg:px-0 lg:text-base pt-2.5 lg:pt-3.5 text-gray-400'>
            Enter a new password to update your password
          </p>
        </div>
        {get(errors, 'submit') && (
          <div className='mb-5 flex bg-primary-coral rounded-lg p-2'>
            <p className='font-inter-medium text-white text-base'>
              {get(errors, 'submit').message}
            </p>
          </div>
        )}
        <div className='pb-4 lg:pb-6'>
          <ControlledTextInput<FormData, 'usernameOrEmail'>
            placeholder='Email Address'
            name='usernameOrEmail'
            control={control}
            shouldUnregister={false}
            defaultValue={emailAddress as string}
            disabled
          />
        </div>
        <div className='pb-4 lg:pb-6'>
          <ControlledTextInput<FormData, 'password'>
            placeholder='Password'
            name='password'
            label='Password'
            control={control}
            shouldUnregister={false}
            rules={PASSWORD_VALIDATIONS}
            type={hidePassword ? 'password' : 'text'}
            defaultValue=''
            startAdornment={
              <FontAwesomeIcon
                icon={faLock}
                fontSize={16}
                className='text-primary-skyblue'
              />
            }
            endAdornment={
              <FontAwesomeIcon
                onClick={() => setHidePassword(!hidePassword)}
                icon={hidePassword ? faEyeSlash : faEye}
                fontSize={20}
                className='text-primary-gray'
                data-testid='password'
              />
            }
            isPassword
          />
        </div>
        <div className='pb-4 lg:pb-6'>
          <ControlledTextInput<FormData, 'confirmPassword'>
            placeholder='Confirm Password'
            name='confirmPassword'
            label='Confirm Password'
            control={control}
            shouldUnregister={false}
            rules={{
              required: 'Please re-enter your password',
              validate: (value) =>
                getValues().password !== value
                  ? 'Passwords do not match'
                  : undefined,
            }}
            type={hideConfirmPassword ? 'password' : 'text'}
            defaultValue=''
            startAdornment={
              <FontAwesomeIcon
                icon={faLock}
                fontSize={16}
                className='text-primary-skyblue'
              />
            }
            endAdornment={
              <FontAwesomeIcon
                onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
                icon={hideConfirmPassword ? faEyeSlash : faEye}
                fontSize={20}
                className='text-primary-gray'
                data-testid='confirmPassword'
              />
            }
            isPassword
          />
        </div>
        <div className='flex w-full'>
          <Button
            label='Change Password'
            fullWidth
            gradientVariant='mintyfresh'
            rightIcon={
              <FontAwesomeIcon
                icon={faChevronsRight}
                fontSize={16}
                className='text-white'
              />
            }
            isSubmitting={isSubmitting}
            onPress={() => {
              if (get(errors, 'submit')) {
                clearErrors('submit');
              }
              handleSubmit(onSubmit)();
            }}
          />
        </div>
      </div>
    </AuthPageLayout>
  );
};

export default ChangePasswordRoute;
