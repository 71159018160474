import { faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowLeftLong,
  faEnvelope,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { get, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import AuthPageLayout from '../components/Layout/AuthPageLayout';
import ControlledTextInput from '../components/inputs/ControlledTextInput';
import {
  ResetPasswordRequestClientPlatformEnum,
  UserControllerApi,
} from '../openapi/yenta';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import { useAppDispatch } from '../slices/store';
import { AnalyticsEventEnum } from '../types';
import { getYentaConfiguration } from '../utils/OpenapiConfigurationUtils';
import { EMAIL_VALIDATIONS } from '../utils/Validations';

interface ForgotPasswordRouteProps {}

interface FormData {
  usernameOrEmail: string;
  password: string;
  submit: string;
}

const ForgotPasswordRoute: React.FC<ForgotPasswordRouteProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    clearErrors,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({ mode: 'onBlur' });

  const onSubmit = async (values: FormData) => {
    try {
      const api = new UserControllerApi(await getYentaConfiguration());
      await api.resetPassword({
        usernameOrEmail: values.usernameOrEmail,
        clientPlatform: ResetPasswordRequestClientPlatformEnum.Consumer,
      });
      navigate('/email-sent');
    } catch (e: any) {
      setError('submit', {
        message:
          e.response.data.errorMessage ||
          'Something went wrong. Please try again.',
      });
      dispatch(showErrorToast('Unable to reset password. Please try again.'));
    }
  };

  return (
    <AuthPageLayout
      eventName={AnalyticsEventEnum.FORGOT_PASSWORD_SCREEN_VIEWED}
    >
      <div className='w-full lg:w-4/5'>
        <div>
          <Link
            to='/'
            className='hidden lg:flex flex-row gap-2.5 p-1.5 rounded border border-reskin-regent-400 text-sm items-center w-min'
          >
            <FontAwesomeIcon icon={faArrowLeftLong} className='text-sm' />{' '}
            <p>Back</p>
          </Link>
        </div>
        <div className='pb-4 lg:pt-6 lg:pb-14'>
          <p className='font-poppins-medium lg:text-left lg:text-5xl text-2xl text-black !lg:leading-[62px]'>
            Forgot Password?
          </p>
          <p className='font-inter-light lg:text-left text-xs lg:px-0 lg:text-base pt-2.5 lg:pt-3.5 text-gray-400'>
            Please enter your email address you used to create your account, and
            we’ll send you a link to reset your password
          </p>
        </div>
        {get(errors, 'submit') && (
          <div className='mb-5 flex bg-primary-coral rounded-lg p-2'>
            <p className='font-inter-medium text-white text-base'>
              {get(errors, 'submit').message}
            </p>
          </div>
        )}
        <div className='pb-4 lg:pb-6'>
          <ControlledTextInput<FormData, 'usernameOrEmail'>
            name='usernameOrEmail'
            control={control}
            label='Email address'
            placeholder='Email address'
            startAdornment={
              <FontAwesomeIcon
                icon={faEnvelope}
                fontSize={20}
                className='text-primary-skyblue'
              />
            }
            shouldUnregister={false}
            rules={{
              required: 'Please enter email address',
              ...EMAIL_VALIDATIONS,
            }}
          />
        </div>

        <div className='flex w-full'>
          <Button
            label='Continue'
            fullWidth
            leftIcon={
              <FontAwesomeIcon
                icon={faPaperPlane}
                fontSize={16}
                className='text-white'
                data-test='loading-icon'
              />
            }
            gradientVariant='mintyfresh'
            isSubmitting={isSubmitting}
            onPress={() => {
              if (get(errors, 'submit')) {
                clearErrors('submit');
              }
              handleSubmit(onSubmit)();
            }}
          />
        </div>
      </div>
      <div className='fixed bottom-11 text-center w-screen lg:hidden text-white -ml-4'>
        Already have an account with us?
        <Link to='/login'>
          <span className='text-reskin-aqua underline pl-1'>Login</span>
        </Link>
      </div>
    </AuthPageLayout>
  );
};

export default ForgotPasswordRoute;
