import { faArrowUpRight } from '@fortawesome/pro-light-svg-icons';
import {
  faEnvelope,
  faEye,
  faEyeSlash,
  faLock,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';
import React, { useState } from 'react';
import { get, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '../components/Button.tsx';
import AuthPageLayout from '../components/Layout/AuthPageLayout.tsx';
import ControlledTextInput from '../components/inputs/ControlledTextInput.tsx';
import {
  AuthControllerApi,
  JwtAuthenticationResponse,
  JwtAuthenticationResponseMfaTypeEnum,
  MfaControllerApi,
} from '../openapi/keymaker/index.ts';
import ErrorService from '../services/ErrorService.ts';
import { fetchAuthUserDetail } from '../slices/AuthSlice.ts';
import { showErrorToast } from '../slices/ToastNotificationSlice.ts';
import { useAppDispatch } from '../slices/store.ts';
import { AnalyticsEventEnum, ErrorCode } from '../types.ts';
import { setAuthCookie } from '../utils/AuthUtils.ts';
import { getErrorMessage } from '../utils/ErrorUtils.ts';
import { getKeymakerConfiguration } from '../utils/OpenapiConfigurationUtils.tsx';
import { EMAIL_VALIDATIONS } from '../utils/Validations.tsx';

interface SignInRouteProps {}

interface FormData {
  usernameOrEmail: string;
  password: string;
  submit: string;
}

const SignInRoute: React.FC<SignInRouteProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { leadId, emailAddress } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [hidePassword, setHidePassword] = useState(true);
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: { usernameOrEmail: emailAddress as string },
  });

  const onMFATypeRedirect = async (data: JwtAuthenticationResponse) => {
    if (data.mfaType === JwtAuthenticationResponseMfaTypeEnum.Sms) {
      try {
        await new MfaControllerApi(await getKeymakerConfiguration()).sendMfaSms(
          data.phoneNumber!,
        );
        navigate(`/2fa?phoneNumber=${data.phoneNumber}`);
      } catch (e: any) {
        ErrorService.notify('Failed to send SMS', e);
        dispatch(showErrorToast('Failed to send SMS'));
      }
    } else {
      navigate('/2fa');
    }
  };

  const onSubmit = async (values: FormData) => {
    try {
      const api = new AuthControllerApi(await getKeymakerConfiguration());
      const { data } = await api.authenticateUser(values);
      setAuthCookie(data?.accessToken!);
      if (data?.mfaType) {
        await onMFATypeRedirect(data);
      } else {
        const isLoginSuccess = await dispatch(
          fetchAuthUserDetail(leadId as string),
        );

        if (!isLoginSuccess) {
          setError('submit', {
            message:
              'Looks like the account you are trying to login as is an agent account. Please login to the ReZEN app instead.',
          });
        } else {
          navigate('/');
        }
      }
    } catch (e: any) {
      setError('submit', {
        message:
          e.response?.data?.errorMessage ||
          getErrorMessage(ErrorService.getErrorCode(e), 'login'),
      });
      const errorCode = ErrorService.getErrorCode(e);
      if (errorCode !== ErrorCode.UNAUTHORIZED) {
        ErrorService.notify('unable to login', e, { meta: { ...values } });
      }
    }
  };

  return (
    <AuthPageLayout eventName={AnalyticsEventEnum.LOGIN_SCREEN_VIEWED}>
      <form onSubmit={handleSubmit(onSubmit)} className='w-full lg:w-4/5'>
        <div className='pb-5 lg:pb-14'>
          <p className='font-poppins-medium lg:text-5xl text-2xl text-reskin-primary-dark text-left'>
            Login
          </p>
        </div>
        {get(errors, 'submit') && (
          <div className='mb-5 flex bg-primary-coral rounded-lg p-2'>
            <p className='font-inter-medium text-white text-base'>
              {get(errors, 'submit').message}
            </p>
          </div>
        )}
        <div className='pb-4 lg:pb-6'>
          <ControlledTextInput<FormData, 'usernameOrEmail'>
            name='usernameOrEmail'
            control={control}
            label='Email address'
            placeholder='Email address'
            startAdornment={
              <FontAwesomeIcon
                icon={faEnvelope}
                fontSize={20}
                className='text-primary-skyblue'
              />
            }
            shouldUnregister={false}
            rules={{
              required: 'Please enter email address',
              ...EMAIL_VALIDATIONS,
            }}
          />
        </div>
        <div className='pb-2'>
          <ControlledTextInput<FormData, 'password'>
            placeholder='Password'
            name='password'
            label='Password'
            control={control}
            shouldUnregister={false}
            rules={{ required: 'Please enter password' }}
            type={hidePassword ? 'password' : 'text'}
            defaultValue=''
            startAdornment={
              <FontAwesomeIcon
                icon={faLock}
                fontSize={20}
                className='text-primary-skyblue'
              />
            }
            endAdornment={
              <FontAwesomeIcon
                onClick={() => setHidePassword(!hidePassword)}
                icon={hidePassword ? faEyeSlash : faEye}
                fontSize={20}
                className='text-primary-gray'
              />
            }
            isPassword
          />
        </div>
        <div className='justify-start flex pb-4 lg:pb-6'>
          <Link to='/forgot-password'>
            <span className='underline font-inter-medium text-sm lg:text-base text-reskin-primary-blue'>
              Forgot Password
            </span>
          </Link>
        </div>
        <div className='flex w-full'>
          <Button
            label='Login'
            type='submit'
            fullWidth
            rightIcon={
              <FontAwesomeIcon
                icon={faArrowUpRight}
                fontSize={16}
                className='text-white'
                data-test='loading-icon'
              />
            }
            variant='primary'
            gradientVariant='mintyfresh'
            isSubmitting={isSubmitting}
            onPress={() => {
              if (get(errors, 'submit')) {
                clearErrors('submit');
              }
            }}
          />
        </div>
      </form>
      <div className='lg:row-span-1 mt-4 lg:mt-6 flex justify-center lg:flex-none lg:justify-start'>
        <p className='font-inter-medium text-sm lg:text-base text-black'>
          Don&apos;t have an account Yet?
          <Link to={leadId ? `/signup?leadId=${leadId}` : '/signup'}>
            <span className='text-reskin-primary-blue underline pl-1'>
              Create Account
            </span>
          </Link>
        </p>
      </div>
    </AuthPageLayout>
  );
};

export default SignInRoute;
