import React from 'react';
import OneRealLogo from '../../assets/img/auth/auth-one-real-logo.svg';
import AuthRightAnimation from '../../assets/img/auth/auth-right-animation.gif';
import { AnalyticsEventEnum } from '../../types';
import { cn } from '../../utils/classUtils';
import AnalyticsEventOnLoad from '../Analytics/AnalyticsEventOnLoad';

interface AuthPageLayoutProps {
  children: React.ReactNode;
  eventName?: AnalyticsEventEnum;
  containerClassName?: string;
}

const AuthPageLayout: React.FC<AuthPageLayoutProps> = ({
  children,
  eventName,
  containerClassName,
}) => {
  return (
    <div className='grid lg:grid-cols-2 grid-cols-1 h-screen'>
      <div className='lg:col-span-1 lg:place-content-start place-content-center lg:bg-none bg-[url(/src/assets/img/auth/auth-mobile-bg.png)] bg-no-repeat bg-contain lg:bg-white bg-primary-navy lg:h-screen py-4'>
        <div className='lg:h-full auth-custom-scroll'>
          <div className='lg:block hidden px-16 pt-16 pb-6'>
            <img src={OneRealLogo} alt='placeholder' width={170} height={50} />
          </div>
          <div
            className={cn(
              'flex flex-col items-center bg-white px-4 py-6 mx-4 rounded-xl lg:mt-20 lg:mb-20 mt-0',
              containerClassName,
            )}
          >
            {children}
          </div>
        </div>
      </div>
      <div className='lg:block hidden h-screen pl-1 pr-4 py-4 relative'>
        <div className='h-full bg-[url(/src/assets/img/auth/auth-right-static.svg)] bg-cover rounded-[20px]'>
          <img
            src={AuthRightAnimation}
            className='absolute bottom-4 left-1/2 -translate-x-1/2 h-2/5'
            alt='animation'
          />
        </div>
      </div>
      {eventName && <AnalyticsEventOnLoad eventName={eventName} />}
    </div>
  );
};

export default AuthPageLayout;
