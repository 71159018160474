import React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import AuthPageLayout from '../components/Layout/AuthPageLayout';

interface EmailSentRouteProps {}

const EmailSentRoute: React.FC<EmailSentRouteProps> = () => {
  const navigate = useNavigate();

  return (
    <AuthPageLayout>
      <div className='w-full lg:w-4/5'>
        <div className='py-8'>
          <p className='font-poppins-semibold md:text-5xl text-4xl text-black text-left'>
            Reset email sent!
          </p>
          <p className='font-inter-medium text-sm pt-4 text-left text-gray-400'>
            You can reset your password by following instructions in your email.
          </p>
        </div>
        <div className='flex w-full pt-9'>
          <Button
            label='Login'
            fullWidth={isMobile}
            gradientVariant='mintyfresh'
            onPress={() => {
              navigate('/login');
            }}
          />
        </div>
      </div>
    </AuthPageLayout>
  );
};

export default EmailSentRoute;
